import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.aside');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-aside',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/component.aside/view.scss */
aside.navbar-vertical.navbar-expand-lg {
  display: flex;
  position: relative;
  width: 320px;
  height: 100%;
  overflow: hidden;
  flex-direction: column;
  background: #fff;
}
@media (max-width: 768px) {
  aside.navbar-vertical.navbar-expand-lg {
    width: 100%;
    z-index: 99999;
    padding: 0;
    border-right: none;
    height: auto;
    flex-direction: row;
    height: 64px;
    flex: none;
  }
}
aside.navbar-vertical.navbar-expand-lg .navbar-brand-container {
  width: 100%;
  display: flex;
  text-align: center;
  background: #fff;
  height: 96px;
}
@media (max-width: 768px) {
  aside.navbar-vertical.navbar-expand-lg .navbar-brand-container {
    height: 67px;
  }
}
aside.navbar-vertical.navbar-expand-lg .navbar-brand-container .navbar-brand {
  flex: auto;
}
aside.navbar-vertical.navbar-expand-lg .navbar-brand-container .navbar-brand img {
  width: 180px;
}
@media (max-width: 768px) {
  aside.navbar-vertical.navbar-expand-lg .navbar-brand-container .navbar-brand img {
    width: 128px;
  }
}
aside.navbar-vertical.navbar-expand-lg .navbar-brand-container .navbar-toggler {
  align-items: flex-end;
  margin-left: 16px;
  margin-right: 16px;
}
aside.navbar-vertical.navbar-expand-lg .navbar-collapse {
  width: 100%;
  flex: auto;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: #fff;
}
@media (max-width: 768px) {
  aside.navbar-vertical.navbar-expand-lg .navbar-collapse {
    height: calc(100% - 64px);
    position: fixed;
    top: 64px;
    left: 0;
    overflow-y: auto;
  }
}
aside.navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav {
  margin: 0;
  font-size: 14px;
  overflow-y: auto;
}
@media (max-width: 768px) {
  aside.navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav {
    display: block;
  }
}
aside.navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav a {
  width: 100%;
}
aside.navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav .nav-item {
  cursor: pointer;
  font-family: "wiz-B";
  margin-left: 24px;
}
@media (max-width: 768px) {
  aside.navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav .nav-item {
    margin-left: 0;
  }
}
aside.navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav .nav-item .nav-link {
  color: #63667B;
  font-size: 18px;
  padding: 22px 32px;
}
aside.navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav .nav-item .nav-link:hover {
  color: var(--wc-blue);
}
aside.navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav .nav-item.active {
  background-color: var(--wc-background);
  border-radius: 10px 0 0 10px;
}
aside.navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav .nav-item.active .nav-link {
  color: var(--wc-blue);
}
aside.navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav .dropdown.active {
  margin-bottom: 18px;
}
aside.navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav .dropdown.active .dropdown-menu {
  display: block;
}
aside.navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav .dropdown .dropdown-item {
  padding-left: 32px;
}
aside.navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav .dropdown .dropdown-item.active {
  color: var(--wc-blue);
  background: linear-gradient(to right, rgba(32, 107, 196, 0.07), var(--wc-background));
}
aside.navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav .dropdown .dropdown-item:hover {
  color: var(--wc-blue);
  background: linear-gradient(to right, rgba(32, 107, 196, 0.07), var(--wc-background));
}

.user-profile {
  padding: 28px;
  padding-top: 0;
}
@media (max-width: 768px) {
  .user-profile {
    padding: 24px;
  }
}
.user-profile .card {
  box-shadow: none;
}
.user-profile .card .card-body {
  padding: 16px 48px;
}
.user-profile .card .card-footer {
  padding: 0;
  background-color: var(--wc-background);
}
.user-profile .clicks {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.user-profile .clicks a {
  display: block;
  width: 100%;
  color: #63667b;
  text-align: center;
}
.user-profile .clicks .click {
  cursor: pointer;
  padding: 0.75rem;
  display: inline-block;
  width: 50%;
}
.user-profile .clicks .click:hover {
  background-color: var(--wc-background-hl);
}
.user-profile .clicks .line {
  margin: 0.75rem 0;
  border-right: 1px solid #63667b;
}
.user-profile .userinfo {
  padding: 0.25rem;
  text-align: left;
}
.user-profile .userinfo .name {
  font-family: "wiz-b";
  color: #2D333F;
  font-size: 20px;
}
.user-profile .userinfo .uclass,
.user-profile .userinfo .mail {
  font-size: 12px;
  color: #9097AD;
}

a {
  cursor: pointer;
}`],
})
export class ComponentAsideComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }
    public async ngOnInit() {
        await this.service.init();
    }

    public isMenuCollapsed: boolean = true;

    public menuActive(url: string) {
        let path = location.pathname;
        if (path.indexOf(url) == 0) return 'active';
        return '';
    }
}

export default ComponentAsideComponent;
import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.saml.metacheck');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-saml-metacheck',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.saml.metacheck/view.scss */
.text-red {
  color: red !important;
}

.information {
  position: relative;
  height: 330px;
}
.information::before {
  content: "";
  width: 500px;
  background: url(/assets/images/background.png) center/contain no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -140px;
  opacity: 0.3;
}
.information .title {
  font-size: 3rem;
  font-family: main-eb;
}
.information .subtitle {
  font-size: 2rem;
}
.information .info-text {
  font-family: main-b;
}
.information .info-text .text-red {
  font-family: main-eb;
}

.input-area {
  z-index: 10;
  width: 400px;
  background-color: rgba(255, 255, 255, 0.9);
}
.input-area .btn-primary {
  height: 44px;
}
.input-area textarea {
  min-height: 240px;
  min-width: 100%;
  resize: both;
}

.wiz-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: contents;
  width: 100%;
  max-width: 800px;
}
.wiz-modal .modal-content {
  width: 100%;
  min-width: 800px;
  height: 60vh;
}`],
})
export class PortalSamlMetacheckComponent implements OnInit {
    public xml = "";
    public json = "";
    public modal = false;

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public async load() {
        const { code, data } = await wiz.call("metadata");
        if (code !== 200) return;
        this.xml = data;
        this.json = this.xml2json(data);
        await this.service.render();
    }

    public xml2json(xml) {
        // DOMParser를 이용하여 XML 문자열을 XML Document로 파싱
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xml, "application/xml");

        // JSON 객체로 변환
        function xmlToJson(node) {
            // 결과를 담을 객체
            let obj = {};

            if (node.nodeType === 1) { // element
                // 해당 노드의 모든 속성을 처리
                if (node.attributes.length > 0) {
                    obj["@attributes"] = {};
                    for (let j = 0; j < node.attributes.length; j++) {
                        const attribute = node.attributes.item(j);
                        obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
                    }
                }
            } else if (node.nodeType === 3) { // text
                obj = node.nodeValue;
            }

            // 자식 노드 처리
            if (node.hasChildNodes()) {
                for (let i = 0; i < node.childNodes.length; i++) {
                    let item = node.childNodes.item(i);
                    let nodeName = item.nodeName;
                    if (typeof (obj[nodeName]) === "undefined") {
                        obj[nodeName] = xmlToJson(item);
                    } else {
                        if (typeof (obj[nodeName].push) === "undefined") {
                            let old = obj[nodeName];
                            obj[nodeName] = [];
                            obj[nodeName].push(old);
                        }
                        obj[nodeName].push(xmlToJson(item));
                    }
                }
            }
            return obj;
        }

        // XML의 root 노드에서 시작하여 JSON으로 변환
        return JSON.stringify(xmlToJson(xmlDoc.documentElement), null, 2);
    }

    public copy(text) {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text);
        }
        else {
            const textArea = document.createElement('textarea');
            textArea.style.opacity = 0;
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.select();
            textArea.setSelectionRange(0, 99999);
            try {
                document.execCommand('copy');
            } catch (err) {
                console.error('복사 실패', err);
            }
            textArea.setSelectionRange(0, 0);
            document.body.removeChild(textArea);
        }
        this.service.success("복사되었습니다.");
    }

    public download(filename, text) {
        const blob = new Blob([text], { type: 'text/plain;charset=utf-8' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }
}

export default PortalSamlMetacheckComponent;
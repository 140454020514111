import '@angular/compiler';
import { ComponentAsideComponent } from './component.aside/component.aside.component';
import { ComponentNavComponent } from './component.nav/component.nav.component';
import { LayoutAsideComponent } from './layout.aside/layout.aside.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { LayoutTopnavComponent } from './layout.topnav/layout.topnav.component';
import { PageLandingComponent } from './page.landing/page.landing.component';
import { PageOidcComponent } from './page.oidc/page.oidc.component';
import { PageSamlComponent } from './page.saml/page.saml.component';
import { ComponentPreComponent } from './component.pre/component.pre.component';
import { PageProcessSamlLogoutComponent } from './page.process.saml.logout/page.process.saml.logout.component';
import { PortalOidcAuthenticateComponent } from './portal.oidc.authenticate/portal.oidc.authenticate.component';
import { PortalOidcRegisterComponent } from './portal.oidc.register/portal.oidc.register.component';
import { PortalOidcStepComponent } from './portal.oidc.step/portal.oidc.step.component';
import { PortalSamlStepComponent } from './portal.saml.step/portal.saml.step.component';
import { PortalSamlLogincheckComponent } from './portal.saml.logincheck/portal.saml.logincheck.component';
import { PortalSamlMetacheckComponent } from './portal.saml.metacheck/portal.saml.metacheck.component';
import { PortalSamlRegisterComponent } from './portal.saml.register/portal.saml.register.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PortalSeasonLoadingComponent } from './portal.season.loading/portal.season.loading.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';

const INDEX_PAGE = "landing";

import { URLPattern } from "urlpattern-polyfill";
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

let app_routes: Routes = [
    {
        component: LayoutEmptyComponent,
        "children": [
            {
                "path": "landing",
                component: PageLandingComponent,
                "app_id": "page.landing"
            },
            {
                "path": "process/saml/logout",
                component: PageProcessSamlLogoutComponent,
                "app_id": "page.process.saml.logout"
            }
        ]
    },
    {
        component: LayoutTopnavComponent,
        "children": [
            {
                "path": "oidc/**",
                component: PageOidcComponent,
                "app_id": "page.oidc"
            },
            {
                "path": "saml/**",
                component: PageSamlComponent,
                "app_id": "page.saml"
            }
        ]
    }
];

export class RouteInfo {
    public path: any = "";
    public segment: any = {};

    constructor() { }
}

window.WizRoute = new RouteInfo();

let patternMatcher = (pattern: any, url: any) => {
    let urlpath = url.map((x: any) => x.path).join("/");
    let testurl = 'http://test/';
    pattern = '/' + pattern;
    urlpath = testurl + urlpath;
    pattern = new URLPattern({ pathname: pattern });
    pattern = pattern.exec(urlpath)
    if (pattern && pattern.pathname) {
        let posParams = {};
        for (let key in pattern.pathname.groups) {
            if (pattern.pathname.groups[key]) {
                posParams[key] = pattern.pathname.groups[key];
            }
        }
        window.WizRoute.path = url.map((x: any) => x.path).join("/");
        window.WizRoute.segment = posParams;

        return { consumed: url, posParams: posParams };
    }
    return null
}

let routes: Routes = [{
    matcher: (url: any) => {
        for (let i = 0; i < app_routes.length; i++) {
            let layout = app_routes[i];
            let layout_childs = layout.children;
            for (let j = 0; j < layout_childs.length; j++) {
                let child = layout_childs[j];
                let matcher = patternMatcher(child.path, url);
                if (matcher)
                    return null;
            }
        }
        return { consumed: url, posParams: {} };
    },
    redirectTo: INDEX_PAGE
}];

for (let i = 0; i < app_routes.length; i++) {
    let layout = app_routes[i];
    let layout_component = layout.component;
    let layout_childs = layout.children;

    let router: any = {
        path: '',
        component: layout_component,
        children: []
    };

    for (let j = 0; j < layout_childs.length; j++) {
        let child = layout_childs[j];
        router.children.push({
            matcher: (url: any) => {
                let matcher = patternMatcher(child.path, url);
                if (matcher) return matcher;
                return null;
            },
            component: child.component
        });
    }
    routes.push(router);
}

@NgModule({ imports: [RouterModule.forRoot(routes)], exports: [RouterModule] })
export class AppRoutingModule { }
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.saml.step');
import { OnInit } from '@angular/core';

@Component({
    selector: 'wiz-portal-saml-step',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.saml.step/view.scss */
a {
  text-decoration: none;
  color: inherit;
}

.steps {
  position: relative;
}
.steps .step {
  text-align: center;
}
.steps .step .step-number {
  z-index: 10;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: white;
  font-size: 24px;
  font-family: main-eb;
}
.steps .step .step-text {
  margin-top: 0px;
  padding: 10px 5px;
}
.steps .step:has(.step-number:hover) .step-number, .steps .step:has(.step-text:hover) .step-number, .steps .step.active .step-number {
  background-color: darkblue;
  color: white;
}
.steps .step:has(.step-number:hover) .step-text, .steps .step:has(.step-text:hover) .step-text, .steps .step.active .step-text {
  font-family: main-eb;
}
.steps hr {
  border-width: 8px;
  border-color: rgba(0, 0, 139, 0.75);
  top: 20px;
  width: 100%;
  opacity: 1;
  transform: translate(50%, -4px);
  z-index: 0;
}`],
})
export class PortalSamlStepComponent implements OnInit {
    public menus = [
        { name: "메타데이터 등록", href: "/saml/register" },
        { name: "메타데이터 확인", href: "/saml/metacheck" },
        { name: "로그인 검증", href: "/saml/logincheck" },
    ];
    public async ngOnInit() { }

    public isActive(link: string) {
        return location.pathname.indexOf(link) === 0;
    }
}

export default PortalSamlStepComponent;
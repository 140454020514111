import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.oidc.authenticate');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-oidc-authenticate',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.oidc.authenticate/view.scss */
.h2 {
  font-weight: normal;
}

pre {
  white-space: pre-wrap;
  word-break: break-all;
}

.content-wrap .row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.content-wrap .btn-logout {
  width: auto;
  padding: 10px 50px;
}
.content-wrap .bt {
  border-top: 2px solid black;
}
.content-wrap .bb {
  border-bottom: 1px solid darkgray;
}
.content-wrap .copy-msg {
  top: 10px;
  right: 10px;
}`],
})
export class PortalOidcAuthenticateComponent implements OnInit {
    public session = {};
    public O = Object;
    public clients = [];
    public client = null;
    public contexts = [
        "urn:oasis:names:tc:SAML:2.0:ac:classes:Password",
        "urn:oasis:names:tc:SAML:2.0:ac:classes:PasswordProtectedTransport",
        "https://refeds.org/profile/mfa",
    ];
    public acr = this.contexts[0];
    /**
     * claim 직접 등록 (acr 포함)
     * response type에 따라 flow 이름 보여주기
     * grant_type
     * - 값 입력 가능
     * - password, client_credentials 선택 가능
     * - password 선택 시 username, password 입력창 생성
     * - pkce
     * - 요청 메시지 보여주기
     */

    constructor(@Inject( Service)         public service: Service,    ) { }

    public error = null;

    public async ngOnInit() {
        await this.service.init();
        if (location.hash.length > 1) {
            const params = new URLSearchParams("?" + location.hash.slice(1));
            const data = {};
            params.forEach((val, key) => {
                data[key] = val;
            });
            window.history.replaceState(null, '', "/oidc/authenticate");
            if (data.error) {
                this.error = error;
                return;
            }
            await wiz.call("token_login", data);
            await this.service.auth.init();
        }
        this.session = this.service.auth.session;
        await this.load();
        const { codeVerifier, codeChallenge } = await this.generatePkceCodes();
        this.codeVerifier = codeVerifier;
        this.data.code_challenge = codeChallenge;
        setTimeout(async () => {
            while(true) {
                await this.getLoginUri();
            }
        }, 0);
        await this.service.render();
    }

    public async load() {
        const { code, data } = await wiz.call("clients");
        if (code !== 200) return;
        this.clients = data;
        await this.service.render();
    }

    public loginUri = "";
    public data = {
        scope: "openid profile",
        claim: "",
        grant_type: "authorization_code",
        response_type: "code",
        response_mode: "form_post",
        pkce: false,
        code_challenge_method: "S256",
        code_challenge: undefined,
        nonce: Math.random().toString(26).slice(2),
        userinfo: true,
    };
    public state = "";
    public codeVerifier = "";

    public responseType = {
        code: true,
        token: false,
        id_token: false,
    };
    public onChangeResType() {
        let type = [];
        if (this.responseType.code) type.push("code");
        if (this.responseType.token) type.push("token");
        if (this.responseType.id_token) type.push("id_token");
        this.data.response_type = type.sort().join(" ");
        this.service.render();
    }
    public flowType() {
        const t = this.responseType;
        if (t.code && !t.token && !t.id_token) return "authorization_code";
        if (!t.code && !t.token && !t.id_token) return "none";
        if ((t.code && t.token) || (t.code && t.id_token)) return "hybrid";
        return "implicit";
    }

    public async onChangePKCE() {
        if (this.data.code_challenge_method === "plain") {
            this.data.code_challenge = this.codeVerifier;
        }
        else {
            this.data.code_challenge = await this.generateCodeChallenge(this.codeVerifier);
        }
        await this.service.render();
    }

    public tmp = null;
    public getBody() {
        const body = JSON.parse(JSON.stringify(this.data));
        const del = () => {
            delete body.code_challenge_method;
            delete body.code_challenge;
        }
        if (body.response_type !== "code") del();
        else if (!body.pkce) del();
        delete body.pkce;
        body.issuer = this.client;
        return body;
    }
    public async getLoginUri() {
        if (!this.client) {
            await this.service.render(1000);
            return;
        }
        const body = this.getBody();
        const tt = JSON.stringify(body);
        if (this.tmp === tt) {
            await this.service.render(1000);
            return;
        }
        this.tmp = tt;

        const { code, data } = await wiz.call("login", body);
        if (code !== 200) {
            this.loginUri = "";
            return;
        }
        this.loginUri = data;
        await this.service.render();
    }
    public async login() {
        if (this.loginUri.length === 0) return;
        const tt = JSON.stringify(this.getBody());
        if (this.tmp !== tt)
            await this.getLoginUri();
        location.href = this.loginUri;
    }

    public prettyUri(uri) {
        const origin = uri.split("?")[0];
        const params = new URLSearchParams("?" + uri.split("?").slice(1).join("?"));
        const arr = [];
        params.forEach((val, key) => {
            arr.push([key, val]);
            if (key === "state" && this.state !== val) {
                this.state = val;
                // this.service.render();
            }
        });
        return `${origin}\n\t?${arr.map(it => `${it[0]}=${it[1]}`).join("\n\t&")}`;
    }

    public logoutOP() {
        location.href = "/api/oidc/logout";
    }

    public async logoutLocal() {
        await wiz.call("logout_local");
        location.reload();
    }

    public isJson(text) {
        const txt = "" + text;
        if (txt.startsWith("{") && txt.endsWith("}")) return true;
        return false;
    }

    public pretty(text) {
        try {
            return JSON.stringify(JSON.parse(text), null, 2);
        } catch {
            return text;
        }
    }

    public parseJWT(text) {
        try {
            let res = text.split('.')[1];
            res = res.replace(/-/g, '+').replace(/_/g, '/');
            res = atob(res);
            res = JSON.parse(res);
            res = JSON.stringify(res, null, 2);
            return res;
        } catch {
            return "Parsing error!";
        }
    }

    // Generate a random string for code_verifier
    generateCodeVerifier(): string {
        const array = new Uint32Array(32);
        window.crypto.getRandomValues(array);
        return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
    }

    // Create SHA-256 hash of the code_verifier
    async generateCodeChallenge(codeVerifier: string): Promise<string> {
        const encoder = new TextEncoder();
        const data = encoder.encode(codeVerifier);
        const hashBuffer = await window.crypto.subtle.digest('SHA-256', data);
        return this.base64UrlEncode(hashBuffer);
    }

    // URL-safe Base64 encode the hash
    private base64UrlEncode(buffer: ArrayBuffer): string {
        const bytes = new Uint8Array(buffer);
        let binary = '';
        for (let i = 0; i < bytes.byteLength; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        const base64 = window.btoa(binary);
        return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    }

    // Example function to generate PKCE codes
    async generatePkceCodes(): Promise<{ codeVerifier: string, codeChallenge: string }> {
        const codeVerifier = this.generateCodeVerifier();
        const codeChallenge = await this.generateCodeChallenge(codeVerifier);
        return { codeVerifier, codeChallenge };
    }
}

export default PortalOidcAuthenticateComponent;
import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.process.saml.logout');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-process-saml-logout',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/page.process.saml.logout/view.scss */
/* src/app/page.process.saml.logout/view.scss: no such file or directory */`],
})
export class PageProcessSamlLogoutComponent implements OnInit {
    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        document.forms[0].submit();
    }
}

export default PageProcessSamlLogoutComponent;
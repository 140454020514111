import '@angular/compiler';
import { ComponentAsideComponent } from './component.aside/component.aside.component';
import { ComponentNavComponent } from './component.nav/component.nav.component';
import { LayoutAsideComponent } from './layout.aside/layout.aside.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { LayoutTopnavComponent } from './layout.topnav/layout.topnav.component';
import { PageLandingComponent } from './page.landing/page.landing.component';
import { PageOidcComponent } from './page.oidc/page.oidc.component';
import { PageSamlComponent } from './page.saml/page.saml.component';
import { ComponentPreComponent } from './component.pre/component.pre.component';
import { PageProcessSamlLogoutComponent } from './page.process.saml.logout/page.process.saml.logout.component';
import { PortalOidcAuthenticateComponent } from './portal.oidc.authenticate/portal.oidc.authenticate.component';
import { PortalOidcRegisterComponent } from './portal.oidc.register/portal.oidc.register.component';
import { PortalOidcStepComponent } from './portal.oidc.step/portal.oidc.step.component';
import { PortalSamlStepComponent } from './portal.saml.step/portal.saml.step.component';
import { PortalSamlLogincheckComponent } from './portal.saml.logincheck/portal.saml.logincheck.component';
import { PortalSamlMetacheckComponent } from './portal.saml.metacheck/portal.saml.metacheck.component';
import { PortalSamlRegisterComponent } from './portal.saml.register/portal.saml.register.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PortalSeasonLoadingComponent } from './portal.season.loading/portal.season.loading.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [
        AppComponent,
        ComponentAsideComponent,
        ComponentNavComponent,
        LayoutAsideComponent,
        LayoutEmptyComponent,
        LayoutTopnavComponent,
        PageLandingComponent,
        PageOidcComponent,
        PageSamlComponent,
        ComponentPreComponent,
        PageProcessSamlLogoutComponent,
        PortalOidcAuthenticateComponent,
        PortalOidcRegisterComponent,
        PortalOidcStepComponent,
        PortalSamlStepComponent,
        PortalSamlLogincheckComponent,
        PortalSamlMetacheckComponent,
        PortalSamlRegisterComponent,
        PortalSeasonAlertComponent,
        PortalSeasonLoadingComponent,
        PortalSeasonLoadingHexaComponent,
        PortalSeasonLoadingSeasonComponent,
        PortalSeasonPagenationComponent,
        PortalSeasonStatusbarComponent,
        PortalSeasonTabComponent,
        PortalSeasonUiDropdownComponent,
        PortalSeasonViewerTreeComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgbModule,
        
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.landing');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-landing',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/page.landing/view.scss */
.login-view {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
}
.login-view .title {
  line-height: 50px;
}
.login-view .title img {
  height: 44px;
}
.login-view .title strong {
  font-family: main-eb;
  font-size: 44px;
}
.login-view .landing-img {
  margin-right: 60px;
}
.login-view .btn-area .btn {
  width: 300px;
  height: 300px;
  flex-direction: column;
}
.login-view .btn-area .btn img {
  height: 180px;
}`],
})
export class PageLandingComponent implements OnInit {

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow();
    }
}

export default PageLandingComponent;
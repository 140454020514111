import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.saml.logincheck');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-saml-logincheck',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.saml.logincheck/view.scss */
.h2 {
  font-weight: normal;
}

.content-wrap .row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.content-wrap .btn-logout {
  width: auto;
  padding: 10px 50px;
}
.content-wrap .bt {
  border-top: 2px solid black;
}
.content-wrap .bb {
  border-bottom: 1px solid darkgray;
}`],
})
export class PortalSamlLogincheckComponent implements OnInit {
    public O = Object;
    public idplist = [];
    public idp = null;
    public contexts = [
        "urn:oasis:names:tc:SAML:2.0:ac:classes:Password",
        "urn:oasis:names:tc:SAML:2.0:ac:classes:PasswordProtectedTransport",
        "https://refeds.org/profile/mfa",
    ];
    public authnContextRef = this.contexts[0];

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public async load() {
        const { code, data } = await wiz.call("idplist");
        if (code !== 200) return;
        this.idplist = data;
        await this.service.render();
    }

    public displayIdP(item) {
        let t = ``;
        const name = item.org_displayname || item.org_name;
        if (name) t += `${name} - ${item.entityid}`;
        else t += item.entityid;
        if (item.org_url) t += ` (${item.org_url})`;
        return t;
    }

    public async login() {
        if (!this.idp) return;
        const params = new URLSearchParams({
            idp_name: this.idp,
            authnContextRef: this.authnContextRef,
        });
        location.href = `/api/saml/login?${params.toString()}`;
    }

    public idpLogout() {
        location.href = "/api/saml/logout?returnTo=/saml/logincheck";
    }

    public async localLogout() {
        await wiz.call("logout_local");
        location.reload();
    }
}

export default PortalSamlLogincheckComponent;